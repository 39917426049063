// @TODO: animate fullscreen exit
// @TODO: @media prefers reduced motion for fullscreen animation
// @TODO: disable document scroll on when in fullscreen
// @TODO: improve API
// @TODO: consider moving iframe for fullscreen, instead of altering parents' styles (https://gomakethings.com/how-to-move-elements-around-in-the-dom-with-vanilla-javascript)
// @TODO: rename `*-base` to `*-host`
// @TODO: rename `share-control` to `embed-control`
// @TODO: change-rename `naturkartan-disable-autoload=true` to`naturkartan-autoload=false`

import { nanoid } from "nanoid"
import { oneLine } from "common-tags"

const appBaseDefault = __EMBED_BASE__ || `https://map.naturkartan.se`

const dataProperties = [
  `app-base`,
  `api-base`,
  `naturkartan-base`,
  `typesense-env`,
  `language`,
  `strict`,
  `strict-show`,
  `strict-exit`,
  `guide`,
  `query`,
  `preselected-site-id`,
  `preselected-site-fit`,
  `quick-categories`,
  `category-names`,
  `show-miniguide`,
  `scroll-zoom`,
  `auto-bounds`,
  `bounds`,
  `zoom`,
  `center`,
  `menu`,
  `menu-sort`,
  `style`,
  `style-control`,
  `zoom-control`,
  `measure-control`,
  `location-control`,
  `print-control`,
  `fullscreen-control`,
  `share-control`,
  `importance-control`,
  `a11y-control`,
  `legend-control`,
  `close-control`,
  `legend`,
  `client`,
  `custom-pin`,
  `user-id`,
  `user-token`,
  `traffic`,

  // legacy
  `meili-env`,
  `edit`,
  `preselected-categories`,
  `menu-active`,
  `filters`,
  `filters-active`,
  `satellite`,
  `stf`,
  `stf-preembed`,
  `bergslagsleden`,
  `stolavsleden`,
  `roslagen`,
  `visitvarmland`,
  `vanilla`,
]

const defaultIframeStyle = oneLine`
  width: 100%;
  height: 100%;
  position: static;
  margin: 0;
  will-change: width, height, top, left;
`

const translations = {
  title: {
    en: `A digital map from Naturkartan.se showing trails and sites mentioned in the article`,
    sv: `En digital karta från Naturkartan.se som visar stigar och platser som nämns i artikeln`,
    de: `Eine digitale Karte von Naturkartan.se mit den im Artikel erwähnten Wanderwegen und Sehenswürdigkeiten`,
    nl: `Een digitale kaart van Naturkartan.se met paden en plaatsen die in het artikel worden genoemd`,
    pl: `Cyfrowa mapa ze strony Naturkartan.se przedstawiająca szlaki i miejsca wspomniane w artykule`,
  },
}

const serializeObj = (obj) => {
  return Object.keys(obj)
    .map((k) => `${k}=${window.encodeURIComponent(obj[k])}`)
    .join(`&`)
}

const dispatchEvent = (iframeId, data = null) => {
  const iframe = document.getElementById(iframeId)
  if (iframe) {
    iframe.parentNode.dispatchEvent(
      new CustomEvent(`naturkartanReceive`, { detail: data })
    )
  }
}

const postMessage = (iframeId, method, data) => {
  const iframe = document.getElementById(iframeId)
  if (iframe) {
    iframe.contentWindow.postMessage(
      {
        owner: `naturkartan`,
        method: method,
        data: data,
      },
      `*`
    )
  }
}

const getIframeData = (iframeId, name) => {
  let data
  const iframe = document.getElementById(iframeId)
  if (iframe) {
    data = iframe.parentNode.getAttribute(`data-naturkartan-${name}`)
    if (data) {
      try {
        data = JSON.parse(data)
      } catch (e) {
        data = null
      }
    }
  }
  return data
}

const inject = (container) => {
  if (
    !container.matches(
      `:not([data-naturkartan-inited])`
      // `[data-naturkartan-query]:not([data-naturkartan-inited])` // @TODO: enable this instead after map editor stops using this embed.js
    )
  )
    return

  let base = appBaseDefault
  const iframeId = `naturkartan-${nanoid()}`

  const options = {
    "iframe-id": iframeId,
    "parent-base": window.location.hostname.replace(/^(www\.)/, ``),
  }

  dataProperties.forEach((prop) => {
    const value = container.getAttribute(`data-naturkartan-${prop}`)
    if (value) {
      if (prop == `app-base`) base = value
      else options[prop] = value
    }
  })

  if (!options.language) {
    // if no language set, use document's language
    const docLang = document.documentElement.lang
    if (docLang) options.language = docLang.substring(0, 2).toLowerCase()
  }

  // options[`naturkartan-base`] = (
  //   options[`naturkartan-base`] || `https://www.naturkartan.se`
  // ).replace(/\/$/, ``)

  const iframe = document.createElement(`iframe`)
  iframe.setAttribute(`src`, base + `?` + serializeObj(options))
  iframe.setAttribute(`id`, iframeId)
  iframe.setAttribute(`width`, `100%`)
  iframe.setAttribute(`height`, `100%`)
  iframe.setAttribute(`frameborder`, 0)
  iframe.setAttribute(`allowfullscreen`, ``)
  iframe.setAttribute(`allow`, `autoplay; clipboard-write; geolocation;`)
  iframe.setAttribute(`style`, defaultIframeStyle)
  iframe.setAttribute(
    `loading`,
    container.dataset.naturkartanDisableAutoload ? `eager` : `lazy`
  )
  iframe.setAttribute(
    `title`,
    translations.title[options.language] || translations.title.sv
  )

  container.innerHTML = ``
  container.setAttribute(`data-naturkartan-inited`, true)
  container.appendChild(iframe)
  container.addEventListener(`naturkartanSend`, (e) => {
    postMessage(iframeId, e.detail.method, e.detail.data)
  })
}

const setFullscreen = (iframeId, active) => {
  const iframe = document.querySelector(`#${iframeId}`)
  if (!iframe) return

  const animDuration = 300

  const insertAnimStyle = () => {
    const style = document.createElement(`style`)
    document.head.appendChild(style)
    style.innerHTML = `
      div[data-naturkartan-query] iframe {
        transition: ${animDuration}ms linear !important;
        transition-property: width, height, top, left, position !important;
      }
    `
    return style
  }

  const prepareParents = (restore) => {
    let el = iframe.parentNode
    while (el && el.parentNode && el !== document.body) {
      if (restore) {
        el.style.transition = el.naturkartanDefaultTransition
        el.style.transform = el.naturkartanDefaultTransform
        el.style.position = el.naturkartanDefaultPosition
        el.style.zIndex = el.naturkartanDefaultZindex
      } else {
        el.naturkartanDefaultTransition = el.style.transition
        el.naturkartanDefaultTransform = el.style.transform
        el.naturkartanDefaultPosition = el.style.position
        el.naturkartanDefaultZindex = el.style.zIndex
        el.style.setProperty(`transition`, `none`, `important`)
        el.style.setProperty(`transform`, `none`, `important`)
        el.style.setProperty(`position`, `static`, `important`)
        el.style.setProperty(`z-index`, `auto`, `important`)
      }
      el = el.parentNode
    }
  }

  if (active) {
    prepareParents(false)

    const rect = iframe.getBoundingClientRect()
    iframe.setAttribute(
      `style`,
      oneLine`
        position: fixed !important;
        z-index: 2147483647 !important;
        top: ${rect.top}px !important;
        left: ${rect.left}px !important;
        width: ${rect.width}px !important;
        height: ${rect.height}px !important;
        margin: 0 !important;
      `
    )

    setTimeout(() => {
      const style = insertAnimStyle()

      iframe.setAttribute(
        `style`,
        oneLine`
          position: fixed !important;
          z-index: 2147483647 !important;
          top: 0 !important;
          left: 0 !important;
          width: 100% !important;
          height: 100% !important;
          margin: 0 !important;
        `
      )

      setTimeout(() => document.head.removeChild(style), animDuration + 100)
    }, 10)
  } else {
    iframe.setAttribute(`style`, defaultIframeStyle)
    prepareParents(true)
  }
}

const init = () => {
  const containers = document.querySelectorAll(
    `div[data-naturkartan-query]:not([data-naturkartan-inited]):not([data-naturkartan-disable-autoload="true"])`
  )

  if (containers.length) containers.forEach((container) => inject(container))

  if (!window.NATURKARTAN_MAP) {
    window.NATURKARTAN_MAP = {
      init,
      inject,
    }

    window.addEventListener(`message`, (e) => {
      const message = e.data
      if (typeof message === `object` && message.owner === `naturkartan`) {
        switch (message.method) {
          case `loaded`: {
            const siteLinks = getIframeData(message.iframeId, `site-links`)
            const editData = getIframeData(message.iframeId, `edit-data`)
            const geojson = getIframeData(message.iframeId, `geojson`)

            postMessage(message.iframeId, `initialData`, {
              siteLinks,
              // @TODO: remove after map editor stops using this embed.js
              editData,
              geojson,
              pageUrl: window.location.href,
              pageTitle: document.title,
              // ---
            })

            dispatchEvent(message.iframeId, { method: `loaded` })

            break
          }

          case `setFullscreen`: {
            setFullscreen(message.iframeId, message.data)

            dispatchEvent(message.iframeId, {
              method: `setFullscreen`,
              active: message.data,
            })

            break
          }

          default: {
            dispatchEvent(message.iframeId, {
              method: message.method,
              data: message.data,
            })

            break
          }
        }
      }
    })
  }
}

if (document.readyState === `loading`) {
  document.addEventListener(`DOMContentLoaded`, init)
} else {
  init()
}
